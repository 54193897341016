<template>
    <el-main>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
            <el-breadcrumb-item>接收指令</el-breadcrumb-item>
        </el-breadcrumb>


      <div id="myOverlay" class="overlay">
        <span class="closebtn" @click="closePanel" title="关闭">x</span>
        <div class="overlay-content">
          <input type="text" @keyup.enter="addQrcode" @keyup.esc="closePanel" v-model="barcode" placeholder="输入二维码后按'回车'添加,'Esc'关闭">
          <el-button type="primary" plain id="addQrBtn" @click="addQrcode">添加</el-button>
        </div>
      </div>
      <div id="container">
          <el-button id="openPanelBtn" @click="openPanel" type="primary">添加二维码</el-button>
          <div id="batchBtn">
            <el-button type="primary" @click="batchExecuteCommand">批量执行</el-button>
            <el-button type="primary" @click="removeSuccess">移除成功项</el-button>
            <el-button type="primary" @click="refreshTable">刷新列表</el-button>
          </div>
        <el-table :data="tableData" stripe border size="small"
                  header-cell-class-name="table_header"
                  @cell-mouse-enter="showTooltip"
                  @cell-mouse-leave="hiddenTooltip"
                  cell-class-name="table_cell" style="width: 100%">
          <el-table-column type="index" label="序号" width="50"/>
          <el-table-column min-width="100" prop="barcode" label="二维码"/>
          <el-table-column prop="name" label="任务名称" width="200"/>
          <el-table-column prop="result" label="执行结果" width="100">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.result==='waiting'" type="warning">
                  等待执行
                </el-tag>
                <el-tag v-if="scope.row.result==='success'" type="success">
                  成功
                </el-tag>
                <el-tag v-if="scope.row.result==='fail'" type="danger">
                  失败
                </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="errorMsg" label="异常信息" min-width="100"/>
          <el-table-column max-width="200" label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.result!=='success'">
                <el-button type="text" @click="executeCommand(scope.row)">
                  执行 <i class="el-icon-s-flag"></i>
                </el-button>
                <el-divider direction="vertical"></el-divider>
              </template>
              <el-button type="text" @click="explainCommand(scope.row)">
                解析 <i class="el-icon-set-up"></i>
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="removeCommand(scope.row)">
                移除 <i class="el-icon-remove"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <table-tooltip :tableCellMouse="tableCellMouse"></table-tooltip>
      </div>
      <el-dialog
          title="解析密文"
          :visible.sync="dialogVisible"
          width="80%"
          center>
        <el-row :gutter="20">
          <el-col :span="12">
              <div class="sub-title">
                <span>加密消息</span>
                <el-button
                    size="mini"
                    type="info"
                    plain
                    @click="copyEncodeContent" style="">复制</el-button>
              </div>
              <el-input
                  type="textarea"
                  :disabled="true"
                  :rows="15"
                  style=""
                  v-model="encodeContent">
              </el-input>
          </el-col>
          <el-col :span="12">
              <div class="sub-title">
                <span>解密消息</span>
                <el-button
                    size="mini"
                    type="info"
                    plain
                    @click="copyDecodeContent" style="">复制</el-button>
              </div>
              <el-input
                  type="textarea"
                  :disabled="true"
                  :rows="15"
                  style=""
                  v-model="decodeContent">
              </el-input>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </el-main>
</template>

<script>
    const prefix = '/task';
    const putExecuteUrl = `${prefix}/executeqrcode`;
    const explainUrl = `${prefix}/readqrcode`;
    import TableTooltip from "../common/TableTooltip";
    export default {
        name: 'Menu1_TaskReader',
        components: {TableTooltip},
        data() {
            return {
                barcode: '',
                tableData: [],
                tableCellMouse: {
                  cellDom: null, // 鼠标移入的cell-dom
                  hidden: null, // 是否移除单元格
                  row: null, // 行数据
                },
                dialogVisible: false,
                encodeContent: '',
                decodeContent: '',
            }
        },
        props:['params'],
        created() {
        },
        methods: {
          // 鼠标移入cell
          showTooltip(row, column, cell) {
            this.tableCellMouse.cellDom = cell;
            this.tableCellMouse.row = row;
            this.tableCellMouse.hidden = false;
          },

          // 鼠标移出cell
          hiddenTooltip() {
            this.tableCellMouse.hidden = true;
          },
          enterBarcode(value){
            this.textarea = this.textarea + value.trim()
            this.jwtcode = this.textarea
            this.barcode = ''
          },
          clearCommand(){
            this.textarea = ''
            this.jwtcode = ''
          },
          executeCommand(row, recall){
            if(row.barcode.length === 0) {
              this.$message({
                message: '指令为空',
                type: 'warning'
              })
              return
            }
            if(row.result === 'success'){
              return;
            }
            this.$axios.put(putExecuteUrl , {encodeContent: row.barcode}).then(res=>{
              let newRow = {barcode: row.barcode}
              newRow.name = res.data.name
              if(res.data.status === 0){
                   newRow.result = 'success'
              }else{
                   newRow.result = 'fail'
                   newRow.errorMsg = res.data.msg
              }
              this.tableData = this.tableData.map(data=>data.barcode===newRow.barcode?newRow:data)
              if(recall){
                this.executeAll()
              }
            })
          },
          executeAll(){
            let dataArray = this.tableData.filter(data=>data.result==='waiting')
            if(dataArray.length>0){
              this.executeCommand(dataArray[0], true)
            }
          },
          explainCommand(row){
            if(row.barcode.length === 0) {
              this.$message({
                message: '指令为空',
                type: 'warning'
              })
              return
            }
            this.dialogVisible = true
            this.encodeContent = row.barcode
            this.decodeContent = ''
            this.$axios.put(explainUrl, {encodeContent: this.encodeContent}).then(res=>{
                this.$message.success("解析成功")
                this.decodeContent = res.data
            })
          },
          removeCommand(row){
            this.tableData = this.tableData.filter(data=>data.barcode!=row.barcode)
          },
          openPanel(){
            this.barcode = ''
            document.getElementById("myOverlay").style.display = "block";
          },
          closePanel(){
            document.getElementById("myOverlay").style.display = "none";
          },
          addQrcode(){
            this.barcode = this.barcode.trim()
            if(this.barcode.length > 0){
              if(this.tableData.filter(data=>data.barcode===this.barcode).length === 0){
                this.tableData.push({barcode:this.barcode, result: 'waiting'})
                this.$message.success('添加成功')
                this.barcode = ''
              }else{
                this.$message.warning('该二维码已存在')
                this.barcode = ''
              }

            }
          },
          batchExecuteCommand(){
            this.$confirm('批量执行等待任务, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.executeAll()
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消操作'
              });
            });
          },
          removeSuccess(){
            this.tableData = this.tableData.filter(data=>data.result!=='success')
          },
          refreshTable(){
            let waitingArray = this.tableData.filter(data=>data.result==='waiting')
            let failArray = this.tableData.filter(data=>data.result==='fail')
            let successArray = this.tableData.filter(data=>data.result==='success')
            this.tableData = [...waitingArray,...failArray,...successArray]
          },
          copyEncodeContent(){
            let textArea = document.createElement("textarea");
            textArea.value = this.encodeContent;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
              // here the magic happens
              document.execCommand('copy') ? res() : rej();
              textArea.remove();
              this.$message.success("复制成功")
            });
          },
          copyDecodeContent(){
            let textArea = document.createElement("textarea");
            textArea.value = this.decodeContent;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
              // here the magic happens
              document.execCommand('copy') ? res() : rej();
              textArea.remove();
              this.$message.success("复制成功")
            });
          }
        }
    }

</script>
<style lang="scss">
// 定义单元格文本超出不换行
.el-table .cell {
  overflow: hidden !important;
  white-space: nowrap !important;
}
</style>
<style scoped>
    .barline el-input{
      width: 80%;
    }

    div#container{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      position: relative;
    }

    #openPanelBtn{
      width: 200px;
      height: 200px;
    }

    #addQrBtn{
      float: left;
      width: 10%;
      padding: 15px;
      margin-left: 10px;
    }

    #batchBtn{
      position: absolute;
      right: 0;
      top: 170px;
    }

    .sub-title{
      text-align: center;
    }
    .sub-title span{
      font-size: x-large;
      font-weight: bold;
    }
    .sub-title .el-button{
      margin-left: 10px;
      margin-bottom: 5px;
    }

    /* The overlay effect with black background */
    .overlay {
      height: 100%;
      width: 100%;
      display: none;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0, 0.9); /* Black with a little bit see-through */
    }

    /* The content */
    .overlay-content {
      position: relative;
      top: 46%;
      width: 80%;
      text-align: center;
      margin-top: 30px;
      margin: auto;
    }

    /* Close button */
    .overlay .closebtn {
      position: absolute;
      top: 20px;
      right: 45px;
      font-size: 60px;
      cursor: pointer;
      color: white;
    }

    .overlay .closebtn:hover {
      color: #ccc;
    }

    /* Style the input field */
    .overlay input[type=text] {
      padding: 15px;
      font-size: 17px;
      border: none;
      float: left;
      width: 80%;
      background: white;
    }

    .overlay input[type=text]:hover {
      background: #f1f1f1;
    }

    /* Style the submit button */
    /*.overlay button{*/
    /*  float: left;*/
    /*  width: 20%;*/
    /*  padding: 15px;*/
    /*  background: #ddd;*/
    /*  font-size: 17px;*/
    /*  border: none;*/
    /*  cursor: pointer;*/
    /*}*/

    /*.overlay button:hover {*/
    /*  background: #bbb;*/
    /*}*/
</style>